@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

input#password1,
input#password2,
input#password3,
input#password4,
input#password5 {
  text-align: center;
}

.mb-10R {
  margin-bottom: 10rem;
}

.bg-white {
  background-color: white !important;
}
.signIn-notes {
  padding: 0 2px 0 5px;
  text-align: left;
}
.mt-20P {
  margin-top: -20px;
}
.ml-20P {
  margin-left: 7px;
  margin-bottom: 25px;
  font-size: 1.3rem !important;
}

.greeting {
  margin-bottom: 5px;
  font-size: 1.3rem !important;
}

.chakra-modal__content-container.css-1oxhx2p {
  top: 20px !important;
}

#main-body > div.chakra-portal > div:nth-child(3) > div {
  z-index: 0;
}

#main-body > div.chakra-portal > div.chakra-modal__overlay.bg-\[\#000\].bg-opacity-50.css-13d1tkw {
  z-index: 0;
}

.list-scroll {
  max-height: 350px;
  overflow: auto;
}

.list-scroll-d {
  max-height: 80vh;
  overflow: auto;
}

.list-border:hover,
.list-border:selected {
  border: 1px solid #f97316;
  content: "";
  border-radius: 2%;
}

input:focus {
  outline: none !important;
  border: 1px solid #f97315 !important;
  box-shadow: 0 0 10px #f97315 !important;
}

input#password1:focus {
  outline: none !important;
  border: 1px solid #f97315 !important;
  box-shadow: 0 0 10px #f97315 !important;
}

.inputWithButton {
  position: relative;
}

.inputWithButton input {
  padding-right: 155px;
}

.inputWithButton button {
  position: absolute;
  right: 0;
  top: 10px;
}
